import './App.css';
import LandingPage from './LandingPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="card">
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} exact />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
